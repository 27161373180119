import AddIcon from '@mui/icons-material/Add'
import { Typography, Grid, Button } from '@mui/material'
import { FC, useState } from 'react'
import { AddAccountItem } from './AddNewAccountItem'
import { AccountTabsValueEnums, Search } from '../../../components'
import { useAppDispatch } from '../../../hooks'
import { searchCustomerAccountsAction } from '../../../store/customerAccounts'
import { Box } from '@mui/system'
import { currencyFormatter } from '../../../utils'
import { NewQuotationForm } from './NewQuotationForm'
import IssueMoreItem from './IssueMoreItem'
import { Bill, Customer } from 'src/services'
import AddQuotationItems from './Quotation/AddQuotationItems'
import AddCustomer from '../Customers/AddCustomer'

export interface CurrentAccountHeaderProps {
  totalAccounts: number
  totalDue: number
  buttonTitle: string
}

const CurrentAccountHeader: FC<CurrentAccountHeaderProps> = ({
  totalAccounts,
  totalDue,
  buttonTitle,
}) => {
  const [showAddAccount, setShowAddAccount] = useState<boolean>(false)
  const [showAddQuotation, setShowAddQuotation] = useState<boolean>(false)
  const [newAccount, setNewAccount] = useState<Bill | null>(null)
  const [newQuotation, setNewQuotation] = useState<Bill | null>(null)
  const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false)
  const [newCustomer, setNewCustomer] = useState<Customer>(null)

  const dispatchAction = useAppDispatch()

  const handleSearch = (searchTerm) => {
    dispatchAction(searchCustomerAccountsAction(searchTerm))
  }

  const isQutation = buttonTitle === 'QUOTATION'
  const isAgreement = buttonTitle === 'AGREEMENT'
  const isAccount = buttonTitle === 'ADD ACCOUNT'


  return (
    <>
      <Grid container alignItems="flex-end">
        <Grid item xs>
          <Box display={'flex'} alignItems="end">
            <Typography variant="h3" component="h3" pl={2.5}>
              {totalAccounts}{' '}
              {isQutation ? 'Quotation' : isAgreement ? 'Agreements' : 'Accounts'}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h3"
              visibility={isQutation || isAgreement ? 'hidden' : 'visible'}
              gutterBottom
              pb={0.2}
              pl={2}
              sx={{ color: 'crimson', fontWeight: 600 }}
            >
              Total Due: {totalDue || totalDue === 0 ? currencyFormatter.format(totalDue) :'' }{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ width: '30%' }}>
          <Search onSearch={handleSearch} />
        </Grid>
        <Grid item sx={{ width: 190, textAlign: 'right' }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            sx={{ backgroundColor: '#eb627a', width: 180, height: 41.5 }}
            onClick={() => {
              if (isAccount) {
                setShowAddAccount(true)
                setShowAddQuotation(false)
              } else if(isQutation || isAgreement) {
                setShowAddAccount(false)
                setShowAddQuotation(true)
              }
            }}
          >
            {buttonTitle}
            <div style={{ width: 9 }} />
          </Button>
        </Grid>
      </Grid>
     {showAddCustomer && (
       <AddCustomer
        onClose={(customer) => {
        setShowAddCustomer(false)
        setNewCustomer(customer)
        setShowAddAccount(true)
        }}
        />
      )}
      {showAddAccount && (
        <AddAccountItem
          customer={newCustomer}
          onClose={(newAccount?: Bill, addCustomer?: boolean) => {
            if (newAccount) {
              setNewAccount(newAccount)
            } else if(addCustomer) {
              setShowAddCustomer(true)
            }
            setNewCustomer(null)
            setShowAddAccount(false)
          }}
        />
      )}
      {showAddQuotation && (
        <NewQuotationForm
          billStatus={isQutation ? 'QUOTATION' : 'AGREEMENT'}
          onClose={(newAccount?: Bill) => {
            if (newAccount) {
              setNewQuotation(newAccount)
            }
            setShowAddQuotation(false)
          }}
        />
      )}
      {newAccount && (
        <IssueMoreItem
          bill_id={newAccount.id}
          customer_id={newAccount.customer.id}
          onClose={() => setNewAccount(null)}
          billInfo={newAccount}
          billType={newAccount.billType}
        />
      )}
      {newQuotation && (
        <AddQuotationItems
          bill_id={newQuotation.id}
          customer_id={newQuotation.customer.id}
          onClose={() => setNewQuotation(null)}
          account={newQuotation}
        />
      )}
    </>
  )
}
export default CurrentAccountHeader
