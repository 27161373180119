import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'
import { withAPIKey } from '../utils'

export type UserLoginRequest = {
  mobile: string
  password: string
}
export type ChangePasswordRequest = {
  newPassword: string
  password: string
  confirmNewPassword?: string
}
export type UserLoginWithFirebaseRequest = {
  mobile: string
  accessToken: string
}

export type Store = {
  name?: string
  storeName?: string
  id?: number
  storeId?: number
  address?: string
  address1?: string
  address2?: string
  storeAddress?: string
  isActive: boolean
  state: string
  createDate: string
  updateDate: string
  gstin?: string
  pan?: string
  userName?: string
  stateCode?: string
  place?: string
  pincode?: number
  actualStateCode?: string
  eWayCredentials?: EWayCredentials
}

export interface EWayCredentials {
  id?: number
  userName: string
  password: string
}

export interface UserLoginData {
  id: number
  store: Store
  config: Config
  isDriveBackUpEnabled: boolean
  name: string
  mobile: string
  webToken: string
  createDate: string
  updateDate: string
  userType: string
  appVersion: string
  deviceId: string
  appVersionNumber: number
  lastUsedAt: string
  lat: number
  lon: number
  activeSubscriptionId: number
  subscriptionEndDate: string
  isOtherStoreFeatureEnabled: boolean
  isMinDaysFeatureEnabled: boolean
  planName: string
  planDiscount: number
  planDiscountIndex: number
  planShownCount: number
  allowZeroRate: boolean
  myRefferalCode: string
  tutorialLink: string
  staffUserId: number
  onboardingDone: boolean | null
  myStores: Store[]
  subscription: Subscription
}
export interface Subscription {
  id: number
  endDate: string
  remainingPoints: number
}

export interface Config {
  id: number
  isMinDaysEnabled: boolean
  isOtherStoreEnabled: boolean
  isGSTEnabled: boolean
  isMixTypeAllowed: boolean
  isRecurringBillsEnabled: boolean
  sgst: number
  cgst: number
  isDriveBackUpEnabled: boolean
  driveFolderName: string
  userId: number
  storeId: number
  updateDate: string
  is30DaysMonthEnabled: boolean
}

export type UpdateStore = {
  name: string
  address: string
  gstin?: string
  pan?: string
  hsnCode?: string
  state?: string
  stateCode?: string
  place?: string
  pincode?: number
  eWayCredentials?: EWayCredentials
}

export type UpdateProfileRequest = {
  name: string
  mobile: string
  store: UpdateStore
}

export type UpdateSettingsRequest = {
  id: number
  isOtherStoreEnabled: boolean
  isGSTEnabled: boolean
  isRemainingDayDiscountEnabled: boolean
  is3InchPrinter: boolean
  isMinDaysEnabled: boolean
  isMixTypeAllowed: boolean
  billFormat: string
  isRecurringBillsEnabled: boolean
  backupEmail?: string
  is30DaysMonthEnabled: boolean
  cgst: number
  sgst: number
  upiId?: string
  enabledChallanBilling?: boolean
}

export interface UserLoginResponse extends Response {
  data: UserLoginData
}

export interface UpdateSettingsAPIResponse extends Response {
  data: Config | null
}

export function createUserLogin(request: UserLoginRequest) {
  return http
    .post<UserLoginRequest>(withAPIKey('/user/password_login'), request)
    .then((response: AxiosResponse) => response)
}

export function updateSettings(request: UpdateSettingsRequest) {
  return http
    .put<UpdateSettingsRequest>(withAPIKey('/user/pvt/config'), request)
    .then((response: AxiosResponse) => response)
}

export function updateUserProfile(request: UpdateProfileRequest) {
  return http
    .put(withAPIKey('/user/pvt'), request)
    .then((response: AxiosResponse) => response)
}

export function createUserFirebaseLogin(request: UserLoginWithFirebaseRequest) {
  const { mobile, accessToken } = request
  return http
    .get<UserLoginRequest>(withAPIKey(`/user/login/${mobile}/${accessToken}`))
    .then((response: AxiosResponse) => response)
}

export function createGetUserProfileData() {
  return http
    .get(`/user/pvt/profile`)
    .then((response: AxiosResponse) => response)
}

export function createChangePassword(request: ChangePasswordRequest) {
  return http
    .post<ChangePasswordRequest>(
      withAPIKey('/user/pvt/change_password'),
      request
    )
    .then((response: AxiosResponse) => response)
}

export type SendOTPDataObject = {
  verificationId: string
  phoneNumber: string
  expirationInSeconds: number
  status: string
}
export interface SendOTPResponse extends Response {
  data: SendOTPDataObject
}
export function createSendOTP(phone: string) {
  return http
    .get(`/user/send_otp/${phone}`)
    .then((response: AxiosResponse) => response)
}

export type VerifyOtpRequest = {
  verificationId: string
  otp: string
}

export function createVerifyOtp(request: VerifyOtpRequest) {
  return http
    .post<VerifyOtpRequest>(withAPIKey('/user/verify_otp'), request)
    .then((response: AxiosResponse) => response)
}
