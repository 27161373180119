export const getAccessToken = (): string => window.localStorage.getItem('token')
export const setAccessToken = async (token: string) =>
  window.localStorage.setItem('token', token)
export const removeToken = () => window.localStorage.removeItem('token')

export const isLoggedIn = (): boolean => !!getAccessToken()

export const getSlipsCheckBoxValue = (): boolean =>
  window.localStorage.getItem('slips_checkbox') !== 'no'
export const setSlipsCheckboxValue = async (checked: boolean) =>
  window.localStorage.setItem('slips_checkbox', checked ? 'yes' : 'no')

export const getBalanceItemsCheckBoxValue = (): boolean =>
  window.localStorage.getItem('balance_items_checkbox') !== 'no'
export const setBalanceItemsCheckBoxValue = async (checked: boolean) =>
  window.localStorage.setItem('balance_items_checkbox', checked ? 'yes' : 'no')

export const getSelectedStoreId = (): string =>
  window.localStorage.getItem('selectedStore')
export const setSelectedStoreId = (selectedStoreId: string) =>
  window.localStorage.setItem('selectedStore', selectedStoreId)
export const removeSelectedStoreId = () =>
  window.localStorage.removeItem('selectedStore')
