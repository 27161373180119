import { FC, useState, useReducer, useEffect } from 'react'
import { Box, Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { Challan, ItemEntryDetail, UpdateEntryDateRequest } from '../../../services'

// Components
import { AppDialog, ChallanSection } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  refreshFetchSlipsAction,
  updateEntryDateAction,
  resetEntryDateAction,
} from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { getFormattedDate, replace12AmWithCurrentTime } from '../../../utils'
import CustomNumberField from '../Users/profile/CustomNumberField'

export interface EditEntryDateProps {
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntryDetail
  accountId: number
}

export const EditEntryDate: FC<EditEntryDateProps> = ({
  onClose,
  onSubmitSuccess,
  item,
  accountId
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: updateEntryDateSuccess,
    error: updateEntryDateError,
    message,
  } = useAppSelector((state) => state.updateEntryDate)
  const [date, setDate] = useState<Date | null>(
    () => new Date(item?.titleDate != null ? item?.titleDate : Date())
  )
  const [isClose] = useState<boolean>(false)
  const [request, setRequest] = useState<UpdateEntryDateRequest | null>(null)
  const [vehicleNo, setVehicleNo] = useState<string|null>(item?.vehicleNo)
  const [ewayBillNo, setEwayBillNo] = useState<number|null>(item?.ewayBillNo)
  const [selectedChallanNo, setSelectedChallanNo] = useState<string>(null)

  useEffect(() => {
    if (updateEntryDateSuccess) {
      dispatchAction(
        showAlertAction({
          message: 'Updated Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(resetEntryDateAction())
    } else if (updateEntryDateError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
      dispatchAction(resetEntryDateAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntryDateSuccess, message, updateEntryDateError])

  const dateChangeHandler = (value) => {
    const newDate = replace12AmWithCurrentTime(value)
    setDate(newDate)

    setRequest({
      ...request,
      entryDate: getFormattedDate(newDate)
    })
  }

  useEffect(() => {
    const { itemEntries } = item
    const entries = itemEntries.map((entry) => {
      return { id: entry.id }
    })
    setRequest({
      entryDate: getFormattedDate(new Date(item?.titleDate)),
      entries: entries,
      vehicleNo: item?.vehicleNo,
      ewayBillNo: item?.ewayBillNo,
      challanId: item?.challanId,
      challanNo: selectedChallanNo
    })
  }, [])

  useEffect(() => {
    setSelectedChallanNo(item.chaalanNumber)
  }, [item])

  const submitHandle = () => {
    const checkRequest = request.entryDate
    if (checkRequest) {
      dispatchAction(updateEntryDateAction(request))
    }
  }

  const onChangeHandler = (e) => {
    const { value } = e.target
    setVehicleNo(value)
    setRequest({
      ...request,
      vehicleNo: value,
    })
  }

  const onEwayChange = (e) => {
    const { value } = e.target
    setEwayBillNo(value)
    setRequest({
      ...request,
      ewayBillNo: value,
    })
  }

  const onChallanChange = (e) => {
    const { value } = e.target
    setSelectedChallanNo(value)
    setRequest({
      ...request,
      challanNo: value,
    })
  }


  return (
    <AppDialog
      id="edit-entry-date"
      title={'Edit Challan'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
      fixHeight={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item={true} xs={5.8} mt={2}>
            <CustomNumberField
                 maxLength={14}
                name='challanNo'
                value={selectedChallanNo}
                label='Challan No'
                fullWidth
                onChange={onChallanChange}
              />
              </Grid>
            <Grid item={true} xs={5.8} mt={2}>
              <DateTimePicker
                label="Select Date"
                value={date}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    variant: 'outlined',
                  },
                }}
                ampmInClock={false}
                onChange={dateChangeHandler}
                format="dd/MM/yyyy hh:mm a"
              />
            </Grid>
            <Grid item={true} xs={5.8} mt={2}>
              <TextField
                    size='small'
                name='vehicleNo'
                fullWidth
                label="Vehicle No"
                value={vehicleNo}
                onChange={onChangeHandler}
              />
            </Grid>
              <Grid item={true} xs={5.8} mt={2}>
              <CustomNumberField
                 maxLength={14}
                name='ewayBillNo'
                value={ewayBillNo}
                label='e-Way Bill No'
                fullWidth
                onChange={onEwayChange}
             
              />
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
