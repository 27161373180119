import { useContext } from 'react'

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from '@mui/material'
import { NavLink as RouterLink, useNavigate } from 'react-router-dom'
import RestoreIcon from '@mui/icons-material/Restore'
import { SidebarContext } from '../../../../contexts/SidebarContext'
import LogoutTwoTone from '@mui/icons-material/LogoutTwoTone'
import LoginTwoTone from '@mui/icons-material/LoginTwoTone'
import PeopleIcon from '@mui/icons-material/People'
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
import ViewListIcon from '@mui/icons-material/ViewList'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import StoreIcon from '@mui/icons-material/Store'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AssessmentIcon from '@mui/icons-material/Assessment'

import { removeToken } from '../../../../utils'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import ApplicationLinks from '../../../../ApplicationLinks'

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.black[90]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
)

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0px 0;
        font-weight: 300;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.dark};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: 300;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          font-weight: 400;

          color: ${theme.colors.alpha.black[80]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.6, 2.5)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.black[80]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.black[80]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          }
          &.Mui-focusVisible {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
            border: 1px dotted;
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.black[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
)

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext)
  const { data } = useAppSelector((state) => state.userData)
  const navigate = useNavigate()
  const dispatchAction = useAppDispatch()
  const handleLogout = (): void => {
    closeSidebar()
    removeToken()
    dispatchAction({ type: 'CLEAR_ALL_STORE_DATA' })
    navigate('/login')
  }
  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.customerLink()}
                  startIcon={<PeopleIcon />}
                  aria-label="Cutomers"
                >
                  Customers
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.currentAccountLink()}
                  startIcon={<ViewListIcon />}
                  aria-label="Accounts"
                >
                  Accounts
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.slipsByDateLink('DATE')}
                  startIcon={<CalendarMonthIcon />}
                  aria-label="View by Date"
                >
                  View by Date
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allChallansLink('OUT')}
                  startIcon={<LogoutTwoTone />}
                  aria-label="All Out Challans"
                >
                  All Out Challans
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allChallansLink('IN')}
                  startIcon={<LoginTwoTone />}
                  aria-label="All In Challans"
                >
                  All In Challans
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allBillsLink()}
                  startIcon={<ReceiptIcon />}
                  aria-label="All Bills"
                >
                  All Bills
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Stock & Store
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.stockItemCategoryLink()}
                  startIcon={<CategoryTwoToneIcon />}
                  aria-label="Stock"
                >
                  Stock
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.profileDetailLink()}
                  startIcon={<StoreIcon />}
                  aria-label=" Store Profile"
                >
                  Store Profile
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.invoiceTnCLink()}
                  startIcon={<StoreIcon />}
                  aria-label="Set Invoice TnC"
                >
                  Set Invoice T&C
                </Button>
              </ListItem>

              {data?.myStores?.length > 1 && (
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={ApplicationLinks.storeChangeLink()}
                    startIcon={<StoreIcon />}
                    aria-label=" Store Profile"
                  >
                    Select Store
                  </Button>
                </ListItem>
              )}

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.staffLink()}
                  startIcon={<GroupAddIcon />}
                  aria-label="Manage Staff Users"
                >
                  Users management
                </Button>
              </ListItem>
            </List>
            {/* <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.manageTaskLink()}
                  startIcon={<AddTaskTwoToneIcon />}
                >
                  Task Management
                </Button>
              </ListItem>
            </List> */}
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              GST Filing
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.gstr1SummaryLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label="GSTR1"
                >
                  GSTR1
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.gstr3bLink()}
                  startIcon={<SummarizeIcon />}
                  aria-label="GSTR 3B"
                >
                  GSTR 3B
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              More
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.activityLink()}
                  startIcon={<DirectionsWalkTwoToneIcon />}
                >
                  Activity
                </Button>
              </ListItem> */}

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.profileSettingsLink()}
                  startIcon={<SettingsIcon />}
                >
                  Settings
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.pendingBillsLink()}
                  startIcon={<ReceiptIcon />}
                >
                  Software usage bills
                </Button>
              </ListItem>

              {process.env.OFFLINE && (
                <>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={ApplicationLinks.subscriptionPlansLink()}
                      startIcon={<ReceiptIcon />}
                    >
                      Purchange/Activate Plan
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={ApplicationLinks.restoreBackup()}
                      startIcon={<RestoreIcon />}
                      aria-label="Restore Backup"
                    >
                      Manage Backups
                    </Button>
                  </ListItem>
                </>
              )}
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.contactUsLink()}
                  startIcon={<ContactSupportIcon />}
                >
                  Contact Us
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  onClick={handleLogout}
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  )
}

export default SidebarMenu
