import { useContext, useEffect } from 'react'

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Typography,
  Link,
} from '@mui/material'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import { SidebarContext } from '../../../contexts/SidebarContext'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'

import HeaderUserbox from './Userbox'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { getUserProfileDataAction } from '../../../store/users'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: flex-end;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
)

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const theme = useTheme()
  const dispatchAction = useAppDispatch()
  const { data, selectedStore } = useAppSelector((state) => state.userData)

  useEffect(() => {
    if (!data) {
      dispatchAction(getUserProfileDataAction())
    }
  }, [data, dispatchAction])

  useEffect(() => {
    console.log(selectedStore)
    if (selectedStore) {
      dispatchAction(getUserProfileDataAction())
    }
  }, [selectedStore])

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px -1px rgba(0, 0, 0, .1)`
            : `0px 1.5px 1px -1px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 1px 1px -1px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {data && (
          <>
            {' '}
            <WhatsAppIcon sx={{ color: 'green' }} />
            <Link
              sx={{ fontSize: 15, mr: 3 }}
              href="https://api.whatsapp.com/send?phone=917976757979"
              color={'green'}
            >
              {' '}
              +917976757979
            </Link>
          </>
        )}

        <HeaderUserbox data={data} />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  )
}

export default Header
