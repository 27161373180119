import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, Challan } from '../types'

export interface FetchAllChallansAPIResponse extends Response {
  data: Challan[]
}

export function fetchAllChallans(isGst: boolean, challanType: string, finYear:string) {
  return http
    .get(`/challan/pvt/recent?is_gst=${isGst}&challan_type=${challanType}&fin_year=${finYear}`)
    .then((response: AxiosResponse) => response)
}
