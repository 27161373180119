import { number } from 'prop-types'
import { Bill } from './items'

export interface Response {
  success: boolean
  message: string
  code: number
  error?: string
}

export interface BaseReducerState {
  loading: boolean
  success: boolean
  message?: string
  error?: string
}

export interface Customer {
  id: number
  name: string
  mobile: string
  address: string
  isDeleted: boolean
  gstin?: string
  state?: string
  stateCode?: string
  pan?: string
  registrationType?: string
  pincode?: string
}

export interface Challan {
  id: number
  date: string
  billId: number
  slipNo: string
  label: string
  account: Bill
}

export interface Staff {
  userName: string
  mobile: string
}
export interface Item {
  id?: number
  itemName: string
  size: string
  width: number
  length: number
  availableQuantity: number
  issuedQuantity: number
  totalQuantity: number
  lostQuantity: number
  rate: number
  area?: number
  areaBasedPerSqFtRate: number
  isCeilingItem: boolean
  isActive: boolean
  category: ItemCategory
  isPlate: boolean
  runningLength?: number
  isSelected?: boolean
  error?: boolean
  isArea?: boolean
  height?
  number
  outAreaEntryId?: number
  hsnCode?: string
}

export interface ItemCategory {
  name: string
  id: number
  displayOrder: number
  createDate: string
  updateDate: string
  defaultDiscountInDays: number
  isCeilingItem: boolean
  isPlate: boolean
  isConsumable: boolean
  isActive: boolean
  items?: Item[] | Partial<Item>[]
  isSelected?: boolean
  isExpanded?: boolean
  //used to create bulk item on onboarding
  selectedItems?: Item[]
  availableQuantity?: number
  issuedQuantity?: number
}

export enum TimeTypeEnum {
  MORNING = 'MORNING',
  EVENING = 'EVENING',
}

export enum EntryType {
  OUT = 'OUT',
  IN = 'IN',
}
export enum BillTypeEnum {
  PER_ITEM = 'PER_ITEM',
  PER_SQRT = 'PER_SQRT',
}

export type SelectOptions = {
  label: string
  id: number
  rate?: number
  availableQuantity?: number
  category: string
  isArea?: boolean
  height?: number
  width?: number
  length?: number
  outAreaEntryId?: number
}

export enum API_ERRORS {
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
}

export interface MonthlyBill {
  intervalName: string
  invoiceNo: string
  invoiceTotal: number
  pdfLink: string
  createDate: string
  startDate: string
  endDate: string
  id: number
  accountId: number
  stateCode?: string
  state?: string
  gstRate?: number
  sgstValue?: number
  cgstValue?: number
  igstValue?: number
  customerGSTIN?: number
  totalTax?: number
}

export interface OnboardingStock {
  title: string
  itemCategories: ItemCategory[]
}

export interface AccountTnc {
  id?: number
  tnc: string
  account: { id: number }
}

export interface MonthlyBillsResponse {
  entries: [MonthlyBill]
  warning: string
  totalMismatchWarning?: string
  messageColor?: string
}

export interface GSTR1SummaryItem {
  title: string
  invCount: number
  taxableAmount: number
  igst: number
  cgst: number
  sgst: number
  cess: number
  invoiceAmount: number
}

export interface GSTR1Report {
  jsonUrl: string
  gstr1SummaryItems: [GSTR1SummaryItem]
}

export interface LedgerResponse {
  entries: [LedgerEntry]
  invoicesTotal: number
  paymentTotal: number
  totalDue: number
}

export interface LedgerEntry {
  id: number
  entryDate: string
  title: string
  debitAmount: number
  creditAmount: number
  balance: number
  invoiceNo: string
  entryType: string
}

export enum CurrentAccountMoreActionsEnum {
  BILL_BETWEEN_TWO_DATES,
  ADD_OTHER_CHARGES,
  LOST_OR_DAMAGE_CHARGES,
  CREATE_NEW_TASK,
  EDIT_ACCOUNT,
  MOVE_BALANCE_TO_NEW_ACCOUNT,
  CLOSE_ACCOUNT,
  ONE_DAY_DISCOUNT_SETTING,
  REOPEN_ACCOUNT,
  RENEW_ACCOUNT,
  DELETE,
  SHOW_PLOT_AREA_DATE_PICKER,
  SHOW_BILL_BTW_TWO_DATES,
}
