import { useState, FC, useEffect } from 'react'
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  Switch,
  Typography,
  TextField,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getUserProfileDataAction } from '../../../../store/users'
import { UpdateSettingsRequest } from 'src/services'
import CustomNumberField from '../profile/CustomNumberField'

export interface AccountSettingsProps {
  onChange: (newSettings: UpdateSettingsRequest) => void
  invalidEmail: boolean
  isOnboarding?: boolean
  invalidUpiId: boolean
}

const AccountSettings: FC<AccountSettingsProps> = ({
  isOnboarding,
  onChange,
  invalidEmail,
  invalidUpiId
}) => {
  const [isDriveBackUpEnabled, setDriveBackupEnabled] = useState<boolean>(false)
  const [is30DaysMonthEnabled, set30DaysMonthEnabled] = useState<boolean>(false)
  const [enabledChallanBilling, setEnabledChallanBilling] = useState<boolean>(false)
  const [isOtherStoreEnabled, setOtherStoreEnabled] = useState<boolean>(false)
  const [isGSTEnabled, setGSTEnabled] = useState<boolean>(false)
  const [isRemainingDayDiscountEnabled, setRemainingDayDiscountEnabled] =
    useState<boolean>(false)
  const [is3InchPrinter, set3InchPrinter] = useState<boolean>(false)
  const [isMixTypeAllowed, setMixTypeAllowed] = useState<boolean>(true)
  const [isReoccuringBillsOn, setReoccuringBillsOn] = useState<boolean>(false)
  const [isMinDaysEnabled, setMinDaysEnabled] = useState<boolean>(false)
  const [billFormat, setBillFormat] = useState('FORMAT_1')
  const [backupEmail, setBackupEmail] = useState(null)
  const [upiId, setUPIId] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [upiError, setUPIError] = useState(null)
  const [sgstRate, setSgstRate] = useState<number>(9)
  const [cgstRate, setCgstRate] = useState<number>(9)

  const { data } = useAppSelector((state) => state.userData)
  const dispatchAction = useAppDispatch()
  const tabs = [
    { value: 'edit_profile', label: 'Edit Profile' },
    { value: 'security', label: 'Passwords/Security' },
  ]

  useEffect(() => {
    dispatchAction(getUserProfileDataAction())
  }, [])

  useEffect(() => {
    setEmailError(invalidEmail)
  }, [invalidEmail])

  useEffect(() => {
    setUPIError(invalidUpiId)
  }, [invalidUpiId])

  useEffect(() => {
    const request = {
      id: data?.config?.id,
      isOtherStoreEnabled: isOtherStoreEnabled,
      isGSTEnabled: data?.config?.isGSTEnabled,
      isRemainingDayDiscountEnabled: isRemainingDayDiscountEnabled,
      is3InchPrinter: is3InchPrinter,
      isMinDaysEnabled: isMinDaysEnabled,
      isMixTypeAllowed: isOnboarding ? true : isMixTypeAllowed,
      billFormat: billFormat,
      isRecurringBillsEnabled: isReoccuringBillsOn,
      backupEmail: backupEmail,
      is30DaysMonthEnabled: is30DaysMonthEnabled,
      sgst: sgstRate,
      cgst: cgstRate,
      upiId: upiId,
      enabledChallanBilling: enabledChallanBilling
      
    }
    onChange(request)
  }, [
    data,
    isDriveBackUpEnabled,
    isOtherStoreEnabled,
    isGSTEnabled,
    isRemainingDayDiscountEnabled,
    is3InchPrinter,
    isMinDaysEnabled,
    isMixTypeAllowed,
    billFormat,
    isReoccuringBillsOn,
    backupEmail,
    is30DaysMonthEnabled,
    sgstRate,
    cgstRate,
    upiId,
    enabledChallanBilling
  ])

  useEffect(() => {
    if (data) {
      setOtherStoreEnabled(data.config?.isOtherStoreEnabled)
      setGSTEnabled(data.config?.isGSTEnabled)
      setRemainingDayDiscountEnabled(data.config?.isRemainingDayDiscountEnabled)
      set3InchPrinter(data.config?.is3InchPrinter)
      setMinDaysEnabled(data.config?.isMinDaysEnabled)
      setMixTypeAllowed(isOnboarding ? true : data.config?.isMixTypeAllowed)
      setReoccuringBillsOn(data.config?.isRecurringBillsEnabled)
      set30DaysMonthEnabled(data.config?.is30DaysMonthEnabled)
      setEnabledChallanBilling(data.config?.enabledChallanBilling)
      setSgstRate(data.config?.sgst)
      setCgstRate(data.config?.cgst)

      setBillFormat(
        data.config?.billFormat ? data.config?.billFormat : 'FORMAT_1'
      )
      setBackupEmail(data.config?.backupEmail)
      setUPIId(data.config?.upiId)
    }
  }, [data])

  return (
    <>
      <Table>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">
                  Enter daily backup email address, and UPI id for payment requests
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                    size='small'
                  value={backupEmail ?? ""}
                  onChange={(e) => {
                    setEmailError(false)
                    setBackupEmail(e.target.value)
                  }}
                  size="small"
                  label="Backup email"
                  sx={{ width: 300, mt: 1 }}
                  error={emailError}
                />
                <TextField
                    size='small'
                  value={upiId ?? ""}
                  onChange={(e) => {
                    setUPIError(false)
                    setUPIId(e.target.value)
                  }}
                  size="small"
                  label="Your UPI ID"
                  sx={{ width: 300, mt: 1, ml:1 }}
                  error={upiError}
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">
                  Set your GST Rate
                </Typography>
              </Grid>
              <Grid item xs>
                <CustomNumberField
                maxLength={4}
                name='sss'
                  value={sgstRate ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSgstRate(event.target.valueAsNumber)
                  }}
                  size="small"
                  label="SGST Rate(%)"
                  sx={{ width: 300, mt: 1 }}
                />
                 <CustomNumberField
                maxLength={4}
                name='sss'
                  value={cgstRate ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCgstRate(event.target.valueAsNumber)
                  }}
                  size="small"
                  label="CGST Rate(%)"
                  sx={{ width: 300, mt: 1, ml:1 }}
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        {/* <TableRow sx={{ height: 72 }}>
              <TableCell>
                <Grid container direction={'column'}>
                  <Grid item xs>
                    <Typography variant="h5">Enable GST Invoicing</Typography>
                  </Grid>
                  <Grid item xs>
                    Enable GST invoicing system{' '}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align='right'>
                <Switch
                  checked={isGSTEnabled}
                  onChange={() => {
                    setGSTEnabled(!isGSTEnabled)
                  }}
                />
              </TableCell>
            </TableRow> */}

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">
                  Enable Monthly/Fortnightly/Weekly Bills
                </Typography>
              </Grid>
              <Grid item xs>
                Please turn on this switch if you generate bill on same account
                after regural interval, such as on every month, every week etc.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isReoccuringBillsOn}
              onChange={() => {
                setReoccuringBillsOn(!isReoccuringBillsOn)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">
                  Enable new bill format (Simpler Format)
                </Typography>
              </Grid>
              <Grid item xs>
               You can select from OLD or NEW bill format, you should try both formats to know which is better for you.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={billFormat === "FORMAT_2"}
              onChange={() => {
                let format = billFormat != "FORMAT_2" ? "FORMAT_2" : "FORMAT_1"
                setBillFormat(format)
              }}
            />
          </TableCell>
        </TableRow>
        {!isOnboarding && (<TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Enable MIX type billing</Typography>
              </Grid>
              <Grid item xs>
                Enable MIX type billing (SQFT and PER ITEM billing in same
                account)
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              id="mix_bill_switch"
              checked={isMixTypeAllowed}
              onChange={() => {
                setMixTypeAllowed(!isMixTypeAllowed)
              }}
            />
          </TableCell>
        </TableRow>)}

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">
                  3 inch thermal printer(For mobile)
                </Typography>
              </Grid>
              <Grid item xs>
                Turn on if you use 3 inch thermal printer
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={is3InchPrinter}
              onChange={() => {
                set3InchPrinter(!is3InchPrinter)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Day discount on left items</Typography>
              </Grid>
              <Grid item xs>
                Enable one day discount on left items{' '}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isRemainingDayDiscountEnabled}
              onChange={() => {
                setRemainingDayDiscountEnabled(!isRemainingDayDiscountEnabled)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Other store feature</Typography>
              </Grid>
              <Grid item xs>
                Enable feature to borrow items from other stores
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isOtherStoreEnabled}
              onChange={() => {
                setOtherStoreEnabled(!isOtherStoreEnabled)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Minimum days feature</Typography>
              </Grid>
              <Grid item xs>
                Enable minimum days feature (example rent for min 10 days, 15
                days)
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isMinDaysEnabled}
              onChange={() => {
                setMinDaysEnabled(!isMinDaysEnabled)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Enable 30 days month feature</Typography>
              </Grid>
              <Grid item xs>
                If you turn this on then while counting the days every month will be considered as 30 days month and there will 360 days in a year.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={is30DaysMonthEnabled}
              onChange={() => {
                set30DaysMonthEnabled(!is30DaysMonthEnabled)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction={'column'}>
              <Grid item xs>
                <Typography variant="h5">Enable advance invoicing</Typography>
              </Grid>
              <Grid item xs>
                 If generate invoices while issueing the items, then turn this on.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={enabledChallanBilling}
              onChange={() => {
                setEnabledChallanBilling(!enabledChallanBilling)
              }}
            />
          </TableCell>
        </TableRow>
      </Table>
    </>
  )
}

export default AccountSettings
