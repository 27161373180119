import { TextField } from '@mui/material'
import { FC } from 'react'

export interface CustomNumberFieldProps {
  disabled?: boolean
  sx?: any
  name: string
  value: number | string
  label: string
  error?: boolean
  helperText?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event) => void
  fullWidth?: boolean
  maxLength: number
  required?: boolean
  inputProps?: any
  InputLabelProps?: any
  inputRef?: any
  size?: any
  InputProps?: any
  id?: string
  style?: any
}
export const CustomNumberField: FC<CustomNumberFieldProps> = ({
  disabled,
  sx,
  name,
  value,
  label,
  error,
  helperText,
  onChange,
  fullWidth,
  maxLength,
  required,
  inputProps,
  InputProps,
  InputLabelProps,
  onKeyDown,
  inputRef,
  size,
  id,
  style,
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.valueAsNumber) {
      if (
        event?.target?.valueAsNumber >= 0 &&
        `${event?.target?.valueAsNumber}`.length <= maxLength
      ) {
        onChange(event)
      }
    } else {
      onChange(event)
    }
  }
  return (
    <TextField
      size="small"
      id={id}
      InputProps={InputProps}
      disabled={disabled}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      InputLabelProps={InputLabelProps}
      inputProps={inputProps}
      sx={sx}
      name={name}
      value={value}
      label={label}
      type={'number'}
      error={error}
      helperText={helperText}
      onChange={onValueChange}
      fullWidth={fullWidth}
      required={required}
      style={style}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    ></TextField>
  )
}

export default CustomNumberField
