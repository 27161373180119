import { Bill, UserLoginData } from 'src/services'
import { addLeadingZero } from '../utils'

export const getBillTypeText = (bill) => {
  let type = bill?.billType
  if (bill?.billType === 'MIX') {
    type =
      bill.totalCeilingArea > 0
        ? 'MIX SQFT'
        : bill.ratePerSqft > 0
        ? 'MIX PLAT'
        : 'PER ITEM'
  } else if (bill.billType === 'PER_SQFT') {
    type = bill.totalCeilingArea > 0 ? 'SQFT PLOT' : 'SQFT PLAT'
  }
  return type?.replace('_', ' ')
}

export type BillType = {
  label: string
  isFlatRate: boolean
  index: number
}

const pcOnly = 'Per Pc Only'
const pcPlot = 'Per Pc + Sqft Area'
const pcPlotFlat = 'Per Pc + Sqft Area + Flat Rate'
const pcPlate = 'Per Pc + Plate Area'
const pcPlateFlat = 'Per Pc + Plate Area + Flat Rate'

const pc = 'Per Pc Only'
const plot = 'Sqft Area'
const plotFlat = 'Sqft Area + Flat Rate'
const plate = 'Plate Area'
const plateFlat = 'Plate Area + Flat Rate'

export const getBillTypes = (userData: UserLoginData, bill: Bill) => {
  if (
    (!bill && userData?.config?.isMixTypeAllowed === true) ||
    bill?.billType === 'MIX'
  ) {
    return [
      { label: pcOnly, isFlatRate: false, index: 0 },
      { label: pcPlot, isFlatRate: false, index: 1 },
      { label: pcPlotFlat, isFlatRate: true, index: 2 },
      { label: pcPlate, isFlatRate: false, index: 3 },
      { label: pcPlateFlat, isFlatRate: true, index: 4 },
    ]
  }
  return [
    { label: pc, isFlatRate: false, index: 0 },
    { label: plot, isFlatRate: false, index: 1 },
    { label: plotFlat, isFlatRate: true, index: 2 },
    { label: plate, isFlatRate: false, index: 3 },
    { label: plateFlat, isFlatRate: true, index: 4 },
  ]
}

export const states = [
  { label: 'JAMMU AND KASHMIR', code: '01' },
  { label: 'HIMACHAL PRADESH', code: '02' },
  { label: 'PUNJAB', code: '03' },
  { label: 'CHANDIGARH', code: '04' },
  { label: 'UTTARAKHAND', code: '05' },
  { label: 'HARYANA', code: '06' },
  { label: 'DELHI', code: '07' },
  { label: 'RAJASTHAN', code: '08' },
  { label: 'UTTAR PRADESH', code: '09' },
  { label: 'BIHAR', code: '10' },
  { label: 'SIKKIM', code: '11' },
  { label: 'ARUNACHAL PRADESH', code: '12' },
  { label: 'NAGALAND', code: '13' },
  { label: 'MANIPUR', code: '14' },
  { label: 'MIZORAM', code: '15' },
  { label: 'TRIPURA', code: '16' },
  { label: 'MEGHALAYA', code: '17' },
  { label: 'ASSAM', code: '18' },
  { label: 'WEST BENGAL', code: '19' },
  { label: 'JHARKHAND', code: '20' },
  { label: 'ODISHA', code: '21' },
  { label: 'CHATTISGARH', code: '22' },
  { label: 'MADHYA PRADESH', code: '23' },
  { label: 'GUJARAT', code: '24' },
  { label: 'MAHARASHTRA', code: '27' },
  { label: 'ANDHRA PRADESH(BEFORE DIVISION)', code: '28' },
  { label: 'KARNATAKA', code: '29' },
  { label: 'GOA', code: '30' },
  { label: 'LAKSHADWEEP', code: '31' },
  { label: 'KERALA', code: '32' },
  { label: 'TAMIL NADU', code: '33' },
  { label: 'PUDUCHERRY', code: '34' },
  { label: 'ANDAMAN AND NICOBAR ISLANDS', code: '35' },
  { label: 'TELANGANA', code: '36' },
  { label: 'ANDHRA PRADESH (NEWLY ADDED)', code: '37' },
  { label: 'LADAKH (NEWLY ADDED)', code: '38' },
  { label: 'OTHER TERRITORY', code: '97' },
  { label: 'CENTRE JURISDICTION', code: '99' },
].sort((a, b) => (a.label > b.label ? 1 : -1))

export const getStateLabelByCode = (code: string) => {
  const updatedCode = addLeadingZero(code)
  const state = states.find((s) => s.code === updatedCode)
  return state ? state.label : null
}
