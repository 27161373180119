import { FC } from 'react'
import { Box } from '@mui/material'

export enum TabsEnums {
  All = 'ALL',
  Out = 'OUT',
  In = 'IN',
  Left = 'REST',
  Payment = 'PAYMENT',
  MonthlyBills = 'BILLS',
  Ledger = 'LEDGER',
}

export enum SlipsScreenType {
  Account = 'ACCOUNT',
  Stock = 'STOCK',
  Date = 'DATE',
}

export enum AccountTabsEnums {
  Current = 'CURRENT',
  Billed = 'BILLED',
  Closed = 'CLOSED',
  Quotation = 'QUOTATIONS',
  Agreement = 'AGREEMENTS',
}

export enum AccountTabsValueEnums {
  OPEN = 'OPEN',
  BILL_CREATED = 'BILL_CREATED',
  CLOSED = 'CLOSED',
  QUOTATION = 'QUOTATION',
  AGREEMENT = 'AGREEMENT',
}

export const TabPanel: FC<{
  children: React.ReactNode
  index: number
  value: number
}> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
