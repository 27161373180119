import { FC, useState, useEffect, Fragment } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Divider,
  Autocomplete,
  TextField,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { AddOtherChargesRequest, Challan, Charges } from '../../../services'

// Components
import { AppDialog, ChallanSection } from '../../../components'
import AddOtherCharges from './AddOtherCharges'
import PreviewAddOtherCharges from './PreviewAddOtherCharges'

// Utils
import { getFormattedDate } from '../../../utils'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addOtherChargesResetAction,
  addOtherChargesAction,
} from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'
import { fetchSlipsListAction } from '../../../store/slips'

export interface OtherChargesProps {
  onClose?: () => void
  bill_id: number
  customer_id: number
  title: string
  isQuotation: boolean
}

const OtherCharges: FC<OtherChargesProps> = ({
  onClose,
  bill_id,
  customer_id,
  title,
  isQuotation,
}) => {
  const dispatchAction = useAppDispatch()
  const { success: addOtherChargesSuccess, message } = useAppSelector(
    (state) => state.addOtherCharges
  )
  const [charges, setCharges] = useState<Charges[]>([])
  const [date, setDate] = useState<Date | null>(null)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [selectedChallan, setSelectedChallan] = useState<Challan>(null)
  const [chargeType, setChargeType] = useState<string>('Other Charge')
  const [dummyCharge, setDummyCharge] = useState<Charges | null>(null)
  const [userDefinedChallanNumber, setDefinedChallanNumber] = useState('')
  const [addOtherChargesRequest, setAddOtherChargesRequest] =
    useState<AddOtherChargesRequest | null>(null)
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(true)
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const chargeTypes = ['Other Charge', 'Security Deposit', 'Security Refund']

  useEffect(() => {
    const formatedDate = getFormattedDate(new Date(date))

    setAddOtherChargesRequest((request) => {
      // Remove the old date from request if user is not selected the date
      if (!date && request?.date) {
        delete request.date
      }
      return {
        ...request,
        ...(charges && { charges }),
        ...(customer_id && { customerId: customer_id }),
        ...(bill_id && { billId: bill_id }),
        ...(date && { date: formatedDate }),
        challanNo: userDefinedChallanNumber,
        challanId: selectedChallan?.id,
        isQuotation: isQuotation,
      }
    })
  }, [
    charges,
    date,
    customer_id,
    bill_id,
    selectedChallan,
    userDefinedChallanNumber,
  ])

  useEffect(() => {
    if (addOtherChargesSuccess) {
      setCharges([])
      setDate(new Date())
      setAddOtherChargesRequest(null)
      dispatchAction(
        showAlertAction({
          message: 'Other Charges Added Successfully',
          severity: 'success',
        })
      )
      onClose()
      dispatchAction(fetchSlipsListAction(bill_id))
      dispatchAction(addOtherChargesResetAction())
    } else if (message) {
      dispatchAction(
        showAlertAction({
          message: message,
          severity: 'error',
        })
      )
      dispatchAction(addOtherChargesResetAction())
    }
  }, [addOtherChargesSuccess, dispatchAction, message])

  useEffect(() => {
    console.log(charges)
  }, [charges])

  const submitHandle = () => {
    if (isQuotation) {
      addOtherChargesRequest.date = getFormattedDate(new Date())
      addOtherChargesRequest.challanNo = '0'
    }
    const { charges, customerId, date, billId, challanNo, challanId } =
      addOtherChargesRequest
    if (
      customerId &&
      billId &&
      charges.length > 0 &&
      (challanId || (challanNo.trim() && date))
    ) {
      dispatchAction(addOtherChargesAction(addOtherChargesRequest))
    } else {
      dispatchAction(
        showAlertAction({
          message: 'Please fill the manadatory fields.',
          severity: 'error',
        })
      )
    }
  }

  return (
    <AppDialog
      id="add-other-charges"
      title={`Add Other Charges (${title})`}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 0 }}>
            <CardContent sx={{ padding: 0 }}>
              <Box
                component="form"
                sx={{
                  mt: 1,
                  pl: 0,
                  '& .MuiTextField-root': { m: 0 },
                }}
                noValidate
                autoComplete="off"
              >
                {!isQuotation && (
                  <ChallanSection
                    challanNo={Number(bill_id)}
                    userDefinedChallanNumber={userDefinedChallanNumber}
                    setDefinedChallanNumber={setDefinedChallanNumber}
                    selectedChallan={selectedChallan}
                    setSelectedChallan={setSelectedChallan}
                    bill_id={bill_id}
                    setReadyToSubmit={setReadyToSubmit}
                    datePickerOpen={datePickerOpen}
                    setDatePickerOpen={setDatePickerOpen}
                    date={date}
                    setDate={setDate}
                    singleRow={false}
                    challan_type={null}
                    mdValue={0}
                  />
                )}
              </Box>
              <Autocomplete
                sx={{ mt: 2 }}
                size="small"
                id="select-charge-type"
                value={chargeType}
                options={chargeTypes}
                onChange={(e, newValue) => {
                  if (newValue === chargeTypes[0]) {
                    setDummyCharge(null)
                  } else {
                    setDummyCharge({
                      amount: 0,
                      chargeTitle: newValue,
                      itemType: null,
                    })
                  }
                  setChargeType(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label={'Select Charge Type'}
                    sx={{ minWidth: '100%' }}
                  />
                )}
              />
              <Divider sx={{ mt: 4, mb: 2 }} />
              <Box
                component="form"
                sx={{
                  mt: 4,
                  '& .MuiTextField-root': { m: 0, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <AddOtherCharges
                  dummyCharge={dummyCharge}
                  addOtherCharge={(charge) => {
                    charge.itemType =
                      chargeType === chargeTypes[0]
                        ? null
                        : chargeType?.replace(' ', '_')?.toUpperCase()
                    setCharges(charges.concat(charge))
                  }}
                />

                {charges.map((item, id) => {
                  return (
                    <Fragment key={id}>
                      {id >= 0 && <Divider />}
                      <PreviewAddOtherCharges
                        charge={item}
                        deleteCharge={() =>
                          setCharges(charges.filter((c, i) => i !== id))
                        }
                        key={id}
                      />
                    </Fragment>
                  )
                })}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AppDialog>
  )
}

export default OtherCharges
