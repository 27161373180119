import {
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
  Typography,
} from '@mui/material'
import logo from './app_logo_blu.png'
import { Link } from 'react-router-dom'
import { isLoggedIn } from '../../utils'

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 100%;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
)

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
)

const LogoSignInner = styled(Box)(
  ({ theme }) => `
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 5;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.header.background};
`
)

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}))

function Logo() {
  return (
    <LogoWrapper to={isLoggedIn() ? '/dashboard' : '/'} sx={{width:140}}>
      <LogoSignWrapper>
        <img
          src={logo}
          alt={'Shuttering App'}
          style={{ width: 40, height: 31, marginTop: 2 }}
        />
      </LogoSignWrapper>
      <Typography variant="h3" sx={{ ml: 0.5, mt: 0 }}>
        TEJSE
      </Typography>
    </LogoWrapper>
  )
}

export default Logo
