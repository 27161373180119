import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Box,
  Grid,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Alert,
  Select,
  Collapse,
  IconButton,
  Typography,
  Checkbox,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import {
  PrepareIssueItem,
  IssueItemRequest,
  TimeTypeEnum,
  Bill,
  Challan,
} from '../../../services'
import { fetchSlipsListAction } from '../../../store/slips'
// Components
import { AppDialog, Loader, ChallanSection } from '../../../components'
import ItemComponent, { HORZ_SPACING } from '../IssueItems/Item'
import PreviewItem from '../IssueItems/PreviewItem'

// Iconse
import CloseIcon from '@mui/icons-material/Close'

// Utils
import { getFormattedDate } from '../../../utils'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  issueItemAction,
  issueItemRequestAction,
  syncSelectOptionsAction,
  fetchtItemOptionsAction,
} from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'
import CustomNumberField from '../Users/profile/CustomNumberField'

export interface IssueMoreItemProps {
  onClose?: (success?: boolean) => void
  bill_id: number
  customer_id: number
  selectedDate?: string
  billInfo?: Bill
  billType: string
  challanNo?: string
}

const IssueMoreItem: FC<IssueMoreItemProps> = ({
  onClose,
  bill_id,
  customer_id,
  selectedDate,
  billInfo,
  billType,
  challanNo,
}) => {
  const dispatchAction = useAppDispatch()

  const navigate = useNavigate()
  const { selectOptions, loading } = useAppSelector(
    (state) => state.manageOptionsItems
  )
  const { data: userData } = useAppSelector((state) => state.userData)

  const {
    list: { data: customerAccounts },
  } = useAppSelector((state) => state.manageCustomerAccounts)

  const {
    success: issueItemSuccess,
    error: issueItemFail,
    message: issueErrorMessage,
  } = useAppSelector((state) => state.issueItems)
  const [popupTitle, setPopupTitle] = useState<string | ReactNode>(
    'Issue item '
  )
  const [items, setItem] = useState<PrepareIssueItem[]>([])
  const [date, setDate] = useState<Date | null>(null)
  const [deposite, setDeposite] = useState<number | null>(null)
  const [vehicleNo, setVehicleNo] = useState<string | null>(null)

  const [minRentalDays, setMinRentalDays] = useState<number | null>(null)
  const [timeType, setTimeType] = useState<string>(TimeTypeEnum.MORNING)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [minRentalDaysError, setMinRentalDaysError] = useState<boolean>(false)
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [selectedChallan, setSelectedChallan] = useState<Challan>(null)
  const [userDefinedChallanNumber, setDefinedChallanNumber] = useState('')

  const [itemRequest, setItemRequest] = useState<IssueItemRequest | null>(null)
  // All manadatory fields are collected
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(true)
  const [checked, setChecked] = useState<boolean>(false)

  const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  useEffect(() => {
    if (selectedDate) {
      setDate(new Date(selectedDate))
    }
  }, [selectedDate])

  useEffect(() => {
    if (customer_id) {
      dispatchAction(
        fetchtItemOptionsAction(customer_id, billInfo?.agreementId)
      )
    }
    return () => {
      dispatchAction(syncSelectOptionsAction())
    }
  }, [customer_id])

  useEffect(() => {
    setItemRequest((request) => {
      const formatedItem =
        items &&
        items.map((data) => {
          const { item } = data
          delete item.label
          delete item.category
          data.timeType = timeType
          return data
        })
      // Remove the old date_entry from request if user is not selected the date
      if (!date && request?.date_entry) {
        delete request.date_entry
      }
      return {
        ...request,
        ...(items && { items: formatedItem }),
        ...(customer_id && { customerId: customer_id }),
        ...(bill_id && { bill_id }),
        ...(date && { date_entry: getFormattedDate(new Date(date)) }),
        ...(deposite && { deposite }),
      }
    })
  }, [items, timeType, date, deposite, customer_id, bill_id])

  useEffect(() => {
    dispatchAction(syncSelectOptionsAction(items))
  }, [items])

  useEffect(() => {
    if (billInfo) {
      const title = `Issue item to (Party Name: ${billInfo?.customer.name}, Site Address: ${billInfo?.siteAddress} )`
      setPopupTitle(title)
    } else if (customerAccounts?.length > 0) {
      const billData = customerAccounts.find((account) => account.id == bill_id)
      const title = `Issue item to (Party Name: ${billData?.customer.name}, Site Address: ${billData?.siteAddress} )`
      setPopupTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill_id, billInfo])

  useEffect(() => {
    if (issueItemSuccess) {
      setItem([])
      setDeposite(0)
      setItemRequest(null)
      dispatchAction(
        showAlertAction({
          message: 'Item saved Successfully',
          severity: 'success',
        })
      )
      onClose(true)
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      dispatchAction(issueItemRequestAction())
      dispatchAction(fetchSlipsListAction(bill_id))
      navigate(`/slips/view-slips/${bill_id}/${customer_id}`)
    } else if (issueErrorMessage) {
      dispatchAction(issueItemRequestAction())
      dispatchAction(
        showAlertAction({
          message: issueErrorMessage,
          severity: 'error',
        })
      )
    }
  }, [issueItemSuccess, issueErrorMessage, dispatchAction])

  const submitHandle = () => {
    const { items, customerId } = itemRequest
    if (
      !customerId ||
      (selectedChallan?.id === 0 && !date) ||
      items.length <= 0 ||
      !selectedChallan
    ) {
      if (selectedChallan?.id === 0 && !date) {
        setDatePickerOpen(true)
      }
      setReadyToSubmit(false)
    } else {
      setReadyToSubmit(true)

      if (userData.config.isMinDaysEnabled) {
        if (!minRentalDays || minRentalDays < 1) {
          setMinRentalDaysError(true)
          return
        }
        itemRequest.items.forEach((item, index) => {
          item.minRentalDays = minRentalDays ? minRentalDays : 1
        })
      }
      itemRequest.vehicleNo = vehicleNo
      itemRequest.challanNo = userDefinedChallanNumber
        ? userDefinedChallanNumber
        : selectedChallan?.slipNo
      itemRequest.challanId = selectedChallan?.id as unknown as string
      dispatchAction(issueItemAction(itemRequest))
    }
  }

  const addItemHandler = (item) => {
    setReadyToSubmit(true)
    const newArray = [item, ...items]
    setItem(newArray)
  }

  const deleteItemHandler = (itemId, item) => {
    setItem(items.filter((data) => data?.item?.id !== itemId))
  }

  const mdValue = 3.5
  const TOP_MARGIN = 0.5
  return (
    <AppDialog
      id="issue-more-item"
      title={popupTitle}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      showErrorMessage={issueItemFail}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        pl={1.5}
        pr={1.5}
        spacing={0}
      >
        <Grid item xs={12}>
          <Box
            component="form"
            sx={{
              backgroundColor: 'white',
              '& .MuiTextField-root': { mt: TOP_MARGIN },
            }}
            noValidate
            autoComplete="off"
          >
            <Box>
              <ChallanSection
                challanNo={challanNo}
                userDefinedChallanNumber={userDefinedChallanNumber}
                setDefinedChallanNumber={setDefinedChallanNumber}
                selectedChallan={selectedChallan}
                setSelectedChallan={setSelectedChallan}
                bill_id={bill_id}
                setReadyToSubmit={setReadyToSubmit}
                datePickerOpen={datePickerOpen}
                setDatePickerOpen={setDatePickerOpen}
                date={date}
                setDate={setDate}
                challan_type={'OUT'}
                mdValue={mdValue}
              />
            </Box>
          </Box>
          <Collapse in={!readyToSubmit}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setReadyToSubmit(true)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 0, mt: 0 }}
            >
              {items.length == 0
                ? 'It seems you did not click ADD button after filling the item details'
                : 'Please fill all mandatory fields!'}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          {/* <Box
            sx={{
              backgroundColor: '#f9f9f9',
              ml: 1,
              mr: 1.8,
              mb: 1,
              borderRadius: 1,
              border: 1,
              borderStyle: 'solid',
              borderColor: '#f0f0f0',
            }}
          >
            <Grid container xs={12}>
              <Grid item xs={6} display={'flex'} alignItems={'center'}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Typography fontWeight={'bold'} ml={1.5}>
                      Select and add items
                    </Typography>
                  </Grid>
                  {billInfo?.ratePerSqft > 0 && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        ml={1.5}
                        sx={{ color: 'crimson' }}
                      >
                        For sqft items enter 0 in the below rate field.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={5} sx={{ textAlign: 'right', pr: 1, height: 48 }}>
                {billType === 'MIX' && (
                  <>
                    <Checkbox onChange={onCheckBoxChange} /> Set default rate to
                    0
                  </>
                )}
              </Grid>
            </Grid>
          </Box> */}
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { mt: TOP_MARGIN, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" sx={{ mt: 2, mb: 1.5 }}>
              SELECT ITEMS
            </Typography>
            <ItemComponent
              selectOptions={selectOptions}
              addItem={addItemHandler}
              submission_type={'SUBMIT'}
              billType={billType}
              defaultRateZero={checked}
            />
            {items.length > 0 && (
              <div
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                {items.map((item, id) => {
                  return (
                    <Fragment key={item?.item?.id}>
                      <Divider />
                      <PreviewItem
                        item={item}
                        deleteItem={(itemId) => {
                          deleteItemHandler(itemId, item)
                        }}
                        billType={billType}
                        key={item?.item?.id}
                      />
                    </Fragment>
                  )
                })}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 'auto',
          mr: 0,
          justifyContent: 'flex-end',
        }}
      >
        <Grid
          pl={1.5}
          pr={1.5}
          container
          spacing={0}
          xs={12}
          sx={{ backgroundColor: 'white', paddingBottom: 0, mb: 0 }}
        >
          <Grid item={true} xs={12} md={mdValue}>
            <CustomNumberField
              name="deposite"
              fullWidth
              value={deposite}
              label="Enter deposit"
              onChange={(event: any) => {
                setDeposite(event.target.value)
              }}
              maxLength={8}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            md={mdValue}
            pl={HORZ_SPACING}
            sx={{ backgroundColor: 'white' }}
          >
            <TextField
              size="small"
              name="vehicleNo"
              fullWidth
              value={vehicleNo}
              label="Enter Vehicle No"
              onChange={(event: any) => {
                if (event.target.value?.length < 11) {
                  setVehicleNo(event.target.value)
                }
              }}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            pl={HORZ_SPACING}
            md={mdValue}
            sx={{ backgroundColor: 'white' }}
          >
            <FormControl sx={{ m: 0 }} fullWidth>
              <InputLabel id="select-time-helper-label">Select Time</InputLabel>
              <Select
                size="small"
                fullWidth
                labelId="select-time-helper-label"
                id="select-time"
                value={timeType}
                name="timeType"
                label="Select Time"
                onChange={(event: SelectChangeEvent) => {
                  setTimeType(event.target.value)
                }}
              >
                <MenuItem value={TimeTypeEnum.MORNING}>Morning</MenuItem>
                <MenuItem value={TimeTypeEnum.EVENING}>
                  Evening (The day is not counted)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {userData.config.isMinDaysEnabled === true && (
            <Grid
              pl={HORZ_SPACING}
              item={true}
              xs={12}
              md={1.5}
              sx={{ backgroundColor: 'white' }}
            >
              <CustomNumberField
                fullWidth
                name="minRentalDays"
                value={minRentalDays ? minRentalDays : ''}
                error={minRentalDaysError}
                label="Min days"
                onChange={(event: any) => {
                  setMinRentalDays(event.target.value)
                  setMinRentalDaysError(false)
                }}
                maxLength={3}
              ></CustomNumberField>
            </Grid>
          )}
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default IssueMoreItem
