import { FC, useRef, useState, useEffect } from 'react'
import { Checkbox, Divider } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box } from '@mui/system'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import LogoutTwoTone from '@mui/icons-material/LogoutTwoTone'
import LoginTwoTone from '@mui/icons-material/LoginTwoTone'
import MoreVertIcon from '@mui/icons-material/MoreVert'
interface AccountActionBarProps {
  refr: any
  showAddEntry: boolean
  showReceiveItems: boolean
  showCheckbox: boolean
  showAddPayment: boolean
  billInfo: Bill
  showBalanceItemsCheckbox: boolean
  onViewPDF: () => void
  onAddPayment: () => void
  setTotalHidden: (hidden: boolean) => void
  setShowOnlyBalanceItems: (showOnly: boolean) => void
  selectTab: (index: number) => void
}

import { getBalanceItemsCheckBoxValue, getSlipsCheckBoxValue, setBalanceItemsCheckBoxValue, setSlipsCheckboxValue } from '../../../utils'
import { CurrentAccountMoreActionsEnum } from '../../../services/types'
import { useParams } from 'react-router'
import IssueMoreItem from '../CurrentAccounts/IssueMoreItem'
import { Bill } from 'src/services'
import SubmitItem from '../CurrentAccounts/SubmitItem'
import { BarButton } from './BarButton'
import { MoreActions } from '../CurrentAccounts/MoreActions'
import ShowMoreActionsComponents from '../CurrentAccounts/ShowMoreActionsComponents'
import { useAppDispatch, useAppSelector } from '../../../hooks'

import { getBillTillTodayPDFResetAction } from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'

export const AccountActionBar: FC<AccountActionBarProps> = ({
  refr,
  showAddEntry,
  showReceiveItems,
  showCheckbox,
  showAddPayment,
  showBalanceItemsCheckbox,
  onViewPDF,
  onAddPayment,
  setTotalHidden,
  setShowOnlyBalanceItems,
  billInfo,
  selectTab,
}) => {
  const { bill_id, customer_id } = useParams()
  const [issueMoreItem, setIssueMoreItem] = useState<boolean>(false)
  const [submitItem, setSubmitItem] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [isChecked, setChecked] = useState(getSlipsCheckBoxValue())
  const [isBalanceItemsChecked, setBalanceItemsChecked] = useState(getBalanceItemsCheckBoxValue())

  const moreRef = useRef()
  const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setTotalHidden(event.target.checked)
    setSlipsCheckboxValue(event.target.checked)
  }

  const onBalanceItemsCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBalanceItemsChecked(event.target.checked)
    setShowOnlyBalanceItems(event.target.checked)
    setBalanceItemsCheckBoxValue(event.target.checked)
  }

  const [showMoreAction, setShowMoreAction] =
    useState<CurrentAccountMoreActionsEnum | null>(null)

  const dispatchAction = useAppDispatch()
  const {
    data: billPdfLink,
    success: getPDFSuccess,
    error: getPDFError,
    message,
  } = useAppSelector((state) => state.billPDF)

  useEffect(() => {
    if (getPDFSuccess) {
      const newWindow = window.open(billPdfLink, '_blank')
      if (newWindow) {
        newWindow.opener = null
      }
      dispatchAction(getBillTillTodayPDFResetAction())
    } else if (getPDFError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(getBillTillTodayPDFResetAction())
    }
  }, [getPDFSuccess, getPDFError, message])

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          height: 46,
          backgroundColor: '#f0f0f0',
          alignItems: 'center',
        }}
      >
        <BarButton
          id="id_button_view_pdf"
          startIcon={<PictureAsPdfIcon />}
          onClick={onViewPDF}
          refr={refr}
          bgColor="#314d67"
          title="View Pdf"
        />

        {showAddEntry && (
          <BarButton
            id="id_button_issue_items"
            startIcon={<LogoutTwoTone />}
            onClick={() => setIssueMoreItem(true)}
            title="Issue Items"
            bgColor="crimson"
          />
        )}
        {showReceiveItems && (
          <BarButton
            id="id_button_receive_items"
            startIcon={<LoginTwoTone />}
            onClick={() => {
              setSubmitItem(true)
            }}
            bgColor="#37A239"
            title="Receive Items"
          />
        )}
        {showAddPayment && (
          <BarButton
            id="id_button_add_payment"
            startIcon={<AddCircleIcon />}
            onClick={onAddPayment}
            title="Add Payment"
            bgColor="#314d67"
          />
        )}
        <BarButton
          id="id_button_more"
          startIcon={<MoreVertIcon />}
          onClick={() => {
            setShowMore(true)
          }}
          refr={moreRef}
          bgColor="#314d67"
          title="More Actions"
        />
        {showCheckbox && (
          <>
            <Checkbox
              sx={{ ml: 2 }}
              id="id_checkbox_hide_entries"
              checked={isChecked}
              onChange={onCheckBoxChange}
            />{' '}
            Hide Total Entries
          </>
        )}
        {showBalanceItemsCheckbox && (
          <>
            <Checkbox
              sx={{ ml: 2 }}
              id="id_checkbox_balance_items"
              checked={isBalanceItemsChecked}
              onChange={onBalanceItemsCheckBoxChange}
            />{' '}
            Show Left Items Only
          </>
        )}
      </Box>
      {issueMoreItem && (
        <IssueMoreItem
          bill_id={+bill_id}
          customer_id={+customer_id}
          onClose={(success) => {
            setIssueMoreItem(false)
            if (success) {
              selectTab(0)
            }
          }}
          billType={billInfo.billType}
          billInfo={billInfo}
        />
      )}
      {submitItem && (
        <SubmitItem
          bill_id={+bill_id}
          customer_id={+customer_id}
          onClose={(success) => {
            setSubmitItem(false)
            if (success) {
              selectTab(0)
            }
          }}
          billType={billInfo.billType}
          bill={billInfo}
        />
      )}

      <ShowMoreActionsComponents
        billInfo={billInfo}
        showMoreAction={showMoreAction}
        setShowMoreAction={setShowMoreAction}
      />

      <MoreActions
        myref={moreRef}
        showMore={showMore}
        bill={billInfo}
        onClose={() => {
          setShowMore(false)
        }}
        setShowMoreAction={setShowMoreAction}
        onChangeTab={(tab: number) => {
          selectTab(tab)
        }}
      />
    </>
  )
}
