import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { fetchMiddleware, loggerMiddleware } from './middlewares'

// Import all reducers
import { updateOneDayDiscount } from './itemCategory/reducers/oneDayDiscount'
import { userData, updateProfile, updateSettings } from './users/reducers'

import { fetchStaff, addStaff, deleteStaff } from './staff/reducers'

import { manageCustomers } from './customers/reducers'
import { addCustomer } from './customers/reducers/addCustomer'
import { deleteCustomer } from './customers/reducers/deleteCustomer'
import { manageCustomerAccounts } from './customerAccounts/reducers'

import { closeAccount } from './customerAccounts/reducers/closeAccount'
import { reopenAccount } from './customerAccounts/reducers/reopenAccount'
import { deleteAccount } from './customerAccounts/reducers/deleteAccount'
import { renewAccount } from './customerAccounts/reducers/renewAccount'
import { getAgreements } from './customerAccounts/reducers/getAgreements'
import {
  subscriptionPlan,
  manageSubscribePopup,
} from './subscriptionPlan/reducers'
import {
  manageItems,
  issueItems,
  updateIssuedItems,
  deletePayment,
  addPayment,
  slipPDF,
  billPDF,
  editBill,
  addOtherCharges,
  addStockItem,
  manageOptionsItems,
  fetchPendingItems,
  deleteChallan,
} from './items/reducers'
import { deleteItem } from './items/reducers/deleteItem'
import {
  manageSlips,
  updateEntryDate,
  fetchChallan,
  getInvoice,
} from './slips/reducers'
import {
  manageBills,
  manageBillsBreakup,
  generateBill,
  merchantEnteredDays,
  accountDetails,
} from './bills/reducers'
import { resetMerchantEnteredDays } from './bills/reducers/resetMerchantEnteredDays'
import { manageAlerts } from './alerts/reducers'
import {
  manageItemCategories,
  deleteCategory,
  addCategory,
  manageDiscountedCategories,
} from './itemCategory/reducers'
import {
  fetchOnboardingStock,
  createOnboardingStock,
} from './onboarding/reducers'
import { fetchMonthlyBill } from './monthlyBills/reducers'
import { getAccountTncState } from './tnc/reducers'
import { updateAccountTncState } from './tnc/reducers/updateTnc'
import { fetchLedger } from './ledger/reducers'
import { fetchAllChallans } from './outwardChallans/reducers'
import { deleteMonthlyBill } from './monthlyBills/reducers/deleteMonthlyBill'
import { fetchAllBills } from './monthlyBills/reducers/fetchAllBills'
import { fetchGstr1 } from './monthlyBills/reducers/fetchGSTR1Json'
import { fetchGSTR3B } from './gstr/reducers'
import { fetchGSTINDetails } from './gstr/reducers/fetchGSTINDetails'

import { createMonthlyBill } from './monthlyBills/reducers/createMonthlyBill'
import { createNewTask, fetchTasks, deleteTask } from './tasks/reducers'
import { fetchActivities } from './activity/reducers'
import { pendingBills, managePaymentDuePopup } from './pendingBills'

// Combime all of them
const appReducer = combineReducers({
  fetchGSTINDetails,
  fetchGSTR3B,
  fetchGstr1,
  fetchAllBills,
  deleteChallan,
  fetchChallan,
  fetchAllChallans,
  accountDetails,
  createOnboardingStock,
  fetchOnboardingStock,
  getAgreements,
  updateAccountTncState,
  getAccountTncState,
  updateOneDayDiscount,
  fetchLedger,
  createMonthlyBill,
  fetchMonthlyBill,
  deleteMonthlyBill,
  resetMerchantEnteredDays,
  userData,
  manageCustomers,
  manageItems,
  manageOptionsItems,
  issueItems,
  manageCustomerAccounts,
  manageSlips,
  addPayment,
  addCustomer,
  slipPDF,
  addStaff,
  deleteStaff,
  deleteCustomer,
  deleteCategory,
  fetchStaff,
  billPDF,
  editBill,
  updateProfile,
  updateSettings,
  closeAccount,
  deleteAccount,
  manageBills,
  updateIssuedItems,
  deletePayment,
  manageAlerts,
  addOtherCharges,
  manageBillsBreakup,
  manageDiscountedCategories,
  generateBill,
  merchantEnteredDays,
  manageItemCategories,
  addCategory,
  addStockItem,
  deleteItem,
  reopenAccount,
  fetchPendingItems,
  renewAccount,
  updateEntryDate,
  getInvoice,
  createNewTask,
  fetchTasks,
  deleteTask,
  fetchActivities,
  subscriptionPlan,
  manageSubscribePopup,
  pendingBills,
  managePaymentDuePopup,
})

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'CLEAR_ALL_STORE_DATA') state = undefined
  return appReducer(state, action)
}

function configureStore() {
  const middlewares = [fetchMiddleware, loggerMiddleware]
  return createStore(
    rootReducer,
    applyMiddleware(...middlewares, thunkMiddleware)
  )
}

const store = configureStore()
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
