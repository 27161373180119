import { Box, Grid, TextField } from '@mui/material'
import { FC, useState, useReducer, useEffect } from 'react'
import { Staff } from '../../../services'
import { AppDialog } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addStaffAction,
  addStaffResetAction,
  fetchStaffsAction,
} from '../../../store/staff'
import { showAlertAction } from '../../../store/alerts'
import CustomNumberField from '../Users/profile/CustomNumberField'

type AddStaffFields = 'name' | 'mobile'
type AddStaffError = Record<AddStaffFields, Record<string, string>>
const addStaffErrors: AddStaffError = {
  name: {
    empty: '',
  },
  mobile: {
    empty: '',
  },
}

type InputBox = {
  value: string
  error: boolean
  helperText: string
}

type AddStaffFieldsState = Record<AddStaffFields, InputBox>

const defaultObj: InputBox = {
  value: '',
  error: false,
  helperText: '',
}

const initialAddStaffState: AddStaffFieldsState = {
  name: { ...defaultObj },
  mobile: { ...defaultObj },
}

type Action = {
  type: 'SET' | 'ERROR'
  payload: {
    name: AddStaffFields
    value: string
  }
}
const addStaffReducer = (state, action) => {
  const {
    type,
    payload: { name, value },
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: { ...state[name], error: false, value: value, helperText: '' },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: addStaffErrors[name]['empty'],
        },
      }
    default:
      return state
  }
}

export interface AddStaffProps {
  children?: React.ReactNode
  staff?: Staff
  onClose?: () => void
}

const AddStaff: FC<AddStaffProps> = ({ onClose, children, staff }) => {
  const {
    success: addStaffSuccess,
    error: addStaffError,
    message,
  } = useAppSelector((state) => state.addStaff)
  const dispatchAction = useAppDispatch()
  const [addStaffData, dispatch] = useReducer(
    addStaffReducer,
    initialAddStaffState
  )
  const { name, mobile } = addStaffData
  const [isClose, setClose] = useState<boolean>(false)

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } })
    }
  }

  const submitHandle = () => {
    if (name.value.length && mobile.value.length) {
      dispatchAction(
        addStaffAction({
          name: name.value,
          mobile: mobile.value,
        })
      )
    } else {
      if (!name.value.length) {
        dispatch({ type: 'ERROR', payload: { name: 'name' } })
      }
      if (!mobile.value.length) {
        dispatch({ type: 'ERROR', payload: { name: 'mobile' } })
      }
    }
  }

  useEffect(() => {
    if (staff) {
      dispatch({
        type: 'SET',
        payload: { name: 'name', value: staff.userName },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'mobile', value: staff.mobile },
      })
    }
  }, [staff, dispatch])

  useEffect(() => {
    // Refresh the staff list
    //dispatchAction(fetchStaffsResetAction())
    if (addStaffSuccess) {
      dispatchAction(fetchStaffsAction())
      dispatchAction(addStaffResetAction())
      onClose()
    } else if (addStaffError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(addStaffResetAction())
    }
  }, [addStaffSuccess, addStaffError, message])
  return (
    <AppDialog
      id="add-staff"
      title="Add User"
      submitButtonText="ADD USER"
      onClose={onClose}
      close={isClose}
      onSubmit={submitHandle}
      maxWidth="sm"
    >
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container>
          <Grid item xs>
            <TextField
              size="small"
              name="name"
              value={name.value}
              label="Name"
              error={name.error}
              helperText={name.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} mt={1}>
            <CustomNumberField
              name="mobile"
              value={mobile.value}
              label="Mobile"
              maxLength={13}
              error={mobile.error}
              required
              helperText={mobile.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            ></CustomNumberField>
          </Grid>
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default AddStaff
