const ApplicationLinks = {
  loginLink: () => '/login',
  productsLink: () => '/products',
  aboutUsLink: () => '/about',
  pricingLink: () => '/pricing',
  contactLink: () => '/contact',
  privacyPolicyLink: () => '/privacy-policy',
  shippingPolicyLink: () => '/shipping-policy',
  refundAndCancelationLink: () => '/refund-and-cancelation',
  termsAndConditionsink: () => '/terms-and-conditions',
  onboardingLink: () => '/onboarding',
  selectStoreLink: () => `/select-store`,
  currentAccountLink: () => `/dashboard/current-account`,
  activityLink: () => `/dashboard/activities`,
  customerLink: () => `/management/customers`,
  profileDetailLink: () => `/management/profile/details`,
  invoiceTnCLink: () => `/management/tnc`,
  profileSettingsLink: () => `/management/profile/settings`,
  changePasswordLink: () => `/management/profile/change-password`,
  subscriptionPlansLink: () => `/management/profile/subscription-plans`,
  pendingBillsLink: () => `/management/profile/due_invoices`,
  storeChangeLink: () => `/management/profile/change`,
  stockItemCategoryLink: () => `/stock/item-category`,
  manageTaskLink: () => `/management/tasks`,
  stockItemLink: () => `/stock/items`,
  allBillsLink: () => `/reports/all-bills`,
  gstr1SummaryLink: () => `/reports/gstr1`,
  gstr3bLink: () => `/reports/gstr-3b`,
  stockItemCompositeLink: (category_id) => `/stock/items/${category_id}`,
  stockPendingItemLink: (item_id) => `/stock/pending-items/${item_id}`,
  createBillLink: (billId) => `/create-bill/${billId}`,
  slipsLink: (bill_id, customer_id) =>
    `/slips/view-slips/${bill_id}/${customer_id}`,
  slipsByDateLink: (screen_type) => `/slips/by-date/${screen_type}`,
  allChallansLink: (challan_type) => `/more/all-challans/${challan_type}`,
  restoreBackup: () => `/more/restore-backup`,
  staffLink: () => `/staff/my-staff`,
  billsLink: (account_id) => `/bills/monthly-bills/${account_id}`,
  quotationLink: (bill_id) => `/quotation/view-quotation/${bill_id}`,
  contactUsLink: () => `/support/contact`,
  errorPageLink: (code) => `/status/${code}`,
}

export default ApplicationLinks
