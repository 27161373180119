import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, Customer } from '../types'

export interface Account {
  id: number
  status: string
  accountType: string
  totalCeilingArea: number
  wallAreaPercentage: number
  totalDue: number
  customer: Customer
  siteAddress: string
  openDate: string
  updateDate: string
  ratePerSqft: number
  remainingPieces: number
}

export interface CustomerAccount {
  id: number
  name: string
  address: string
  mobile: string
  accounts: Account[]
}

export type RenewAccountRequest = {
  billId: number
  fromDate: string
  customerId: number
  shouldShiftDueAmount: boolean
}

export interface RenewAccountAPIResponse extends Response {
  data: boolean
}

export interface FetchCustomerAccountsResponse extends Response {
  data: CustomerAccount[]
}

export interface Agreement {
  id: number
  agreementTitle: string
}

export interface GetAgreementsAPIResponse extends Response {
  data: Agreement[]
}

export function fetchCustomerAccounts(status: string) {
  return http
    .get(`/bill/pvt/v6/${status}`)
    .then((response: AxiosResponse) => response)
}

export function closeAccount(accountId: number) {
  return http
    .put(`/bill/pvt/close_account/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function reopenAccount(accountId: number) {
  return http
    .get(`/bill/pvt/mark_unbilled/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function deleteAccount(accountId: number) {
  return http
    .delete(`/bill/pvt/delete/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function renewAccount(request: RenewAccountRequest) {
  return http
    .put('/bill/pvt/renew', request)
    .then((response: AxiosResponse) => response)
}

export function getAgreements(customerId: number) {
  return http
    .get(`/bill/pvt/agreements?customer_id=${customerId}`)
    .then((response: AxiosResponse) => response)
}
