import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, MonthlyBill, MonthlyBillsResponse, GSTR1Report } from '../types'

export interface CreateMonthlyBillAPIResponse extends Response {
  data: MonthlyBill
}

export interface DeleteMonthlyBillAPIResponse extends Response {
  data: MonthlyBill
}

export interface FetchMonthlyBillsAPIResponse extends Response {
  data: MonthlyBillsResponse[]
}

export interface Gstr1SummaryReportAPIResponse extends Response {
  data: GSTR1Report
}

export type CreateMonthlyBillRequest = {
  accountId: number
  fromDate: string
  toDate: string
  createDate: string
  invoiceNo: number
  discount?: number
  challanId?: number
}

export const createMonthlyBill = (request: CreateMonthlyBillRequest) => {
  return http
    .post('/interim_bill/pvt', request)
    .then((response: AxiosResponse) => response)
}

export function fetchMonthlyBill(accountId: number, from: string, to: string, isGst: boolean) {
  let url = `/interim_bill/pvt?account_id=${accountId}&isGst=${isGst}`
  if(isGst != null) {
    url = `${url}&gst_type=${isGst ? "ALL_GST" : "NONE"}`
  }
  if(from) {
    url = `${url}&from=${from}`
  }
  if(to) {
    url = `${url}&to=${to}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export function fetchAllBills(finYear: string,  isGst: boolean) {
  let url = `/interim_bill/pvt?account_id=0&isGst=${isGst}&fin_year=${finYear}`
  if(isGst != null) {
    url = `${url}&gst_type=${isGst ? "ALL_GST" : "NONE"}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export function fetchGSTR1Json(from: string, to: string) {
  let url = `/interim_bill/pvt/gstr1_json?isGst=true`
  
  if(from) {
    url = `${url}&from=${from}`
  }
  if(to) {
    url = `${url}&to=${to}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export function deleteMonthlyBill(id: number) {
  return http
    .delete(`/interim_bill/pvt?id=${id}`)
    .then((response: AxiosResponse) => response)
}
