import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {
  fetchLedgerAction,
  fetchLedgerResetAction,
} from '../../../store/ledger'
import { Empty } from '../../../components'
import { getDisplayDateTime, getShortFormattedDate } from '../../../utils'
import { Bill } from 'src/services'

export interface LedgerProps {
  bill: Bill
}

export const Ledger: FC<LedgerProps> = ({ bill }) => {
  const dispatchAction = useAppDispatch()
  const {
    success,
    data: ledgerResponse,
    message,
    loading,
  } = useAppSelector((state) => state.fetchLedger)

  useEffect(() => {
    dispatchAction(fetchLedgerResetAction())
  }, [])

  useEffect(() => {
    if (bill) {
      dispatchAction(fetchLedgerAction({ accountId: bill.id }))
    }
  }, [bill])

  useEffect(() => {
    if (!success && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [message])

  const columns: GridColDef[] = [
    {
      field: 'title',
      width: 200,
      headerName: 'Particular',
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {value.row.title}{' '}
          {value.row.invoiceNo ? ` (${value.row.invoiceNo})` : ''}
        </Typography>
      ),
    },

    {
      field: 'entryDate',
      headerName: 'Entry Date',
      width: 220,
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {getShortFormattedDate(value.row.entryDate)}
        </Typography>
      ),
    },

    {
      field: 'debitAmount',
      width: 180,
      flex: 1,
      headerName: 'Invoice(DEBT)',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {value.row.debitAmount === 0 ? '-' : value.row.debitAmount}
        </Typography>
      ),
    },

    {
      field: 'creditAmount',
      width: 150,
      flex: 1,
      headerName: 'Payment(CRDT)',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {value.row.creditAmount === 0 ? '-' : Math.round(value.row.creditAmount).toFixed(2)}
        </Typography>
      ),
    },

    {
      field: 'balance',
      width: 150,
      flex: 1,
      headerName: 'Balance',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography
          fontWeight={value.row.id < 0 ? 'bold' : '400'}
          sx={{ color: value.row.balance > 0 ? 'crimson' : 'green' }}
        >
          {value.row.balance}
        </Typography>
      ),
    },

    {
      field: 'entryType',
      align: 'center',
      disableExport: true,
      headerAlign: 'center',
      width: 100,
      headerName: 'Entry Type',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (value) => (
        <>
          <div className={`entryType${value.row.entryType}`}>
            {value.row.entryType}
          </div>
        </>
      ),
    },

    {
      field: 'dsdh',
      headerName: 'Actions  ',
      headerAlign: 'right',
      disableExport: true,
      sortable: false,
      filterable: false,
      width: 100,
      flex: 1,
      align: 'right',
      headerClassName: 'super-app-theme--header',

      renderCell: (value) => {
        return (
          <>
            <Stack direction="row" alignItems="right">
              <Tooltip title="View Bill" arrow>
                <IconButton
                  disabled={!value.row.pdfLink}
                  onClick={() => {
                    window.open(value.row.pdfLink, '_blank')
                  }}
                >
                  {' '}
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        )
      },
    },
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          backgroundColor: '#f0f0f0',
          height: 46,
          alignItems: 'center',
          display: 'flex',
          borderRadius: 0,
          padding: 0,
        }}
      >
        <GridToolbarExport
          startIcon={<PictureAsPdfIcon />}
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{
            hideFooter: true,
          }}
        />
        <Box
          sx={{ textAlign: 'right', alignItems: 'flex-end', flex: 1, pr: 1.2 }}
        >
          Ac: {bill.id},Party Name: {bill.customer.name}, Site Address:{' '}
          {bill.siteAddress}
        </Box>
      </GridToolbarContainer>
    )
  }

  return (
    <>
      <Box
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: '#f9f9f9',
            color: 'black',
          },
        }}
      >
        {ledgerResponse === null || ledgerResponse.entries.length === 0 ? (
          <Empty>
            {' '}
            {ledgerResponse === null
              ? ''
              : 'No ledger entry found for this account'}{' '}
          </Empty>
        ) : (
          <DataGrid
            rows={ledgerResponse.entries}
            localeText={{ toolbarExport: 'Export PDF' }}
            slots={{
              toolbar: CustomToolbar,
            }}
            sx={{
              '@media print': {
                '& .MuiDataGrid-main': {
                  zoom: '0.8',
                  fontSize: 15,
                },
              },
            }}
            slotProps={{
              toolbar: {
                printOptions: {
                  pageStyle:
                    '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(222, 0, 0, 0.87); }',
                },
              },
            }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[20, 50]}
          />
        )}
      </Box>
    </>
  )
}

export default Ledger
