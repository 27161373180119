import { FC } from 'react'
import {
  Grid,
  TextField,
  Button,
  Box,
  Divider,
  IconButton,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import { PrepareIssueItem, EntryType } from '../../../services'

type PreviewItem = {
  item: PrepareIssueItem
  entryType?: EntryType
  deleteItem: (id: number, outEnteryID?: number) => void
  submission_type?: 'LOST' | 'SUBMIT'
  billType: string
}
const PreviewItem: FC<PreviewItem> = ({
  item,
  entryType = EntryType.OUT,
  deleteItem,
  submission_type = 'SUBMIT',
  billType,
}) => {
  const fieldWidth =
    entryType === EntryType.OUT ||
    submission_type === 'LOST' ||
    (entryType === EntryType.IN && billType === 'MIX')
      ? 240
      : 372
  const mdValue =
    entryType === EntryType.OUT ||
    submission_type === 'LOST' ||
    (entryType === EntryType.IN && billType === 'MIX')
      ? 3.5
      : 5.25
  return (
    <>
      <Grid container spacing={0} xs={12}>
        <Grid item={true} xs={12} md={mdValue}>
          <TextField
            size="small"
            value={item?.item?.label}
            sx={{ minWidth: fieldWidth, '& fieldset': { border: 'none' } }}
          />
        </Grid>

        {item.height && item.length && item.width ? (
          <>
            <Grid
              item={true}
              xs={12}
              md={1.5}
              pl={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              height={item?.height}
            </Grid>
            <Grid
              item={true}
              xs={12}
              md={1.5}
              pl={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              width={item?.width}
            </Grid>
            <Grid
              item={true}
              xs={12}
              md={1.5}
              pl={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Length={item?.length}
            </Grid>
            <Grid
              item={true}
              xs={12}
              md={1.5}
              pl={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Rate={item?.rate}
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item={true}
              xs={12}
              md={mdValue}
              pl={4}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {item?.quantity}
            </Grid>

            {entryType === EntryType.OUT && (
              <Grid
                item={true}
                xs={12}
                md={submission_type === 'LOST' ? 0 : 3.5}
                sx={{ display: 'flex', alignItems: 'center', pl: 3 }}
              >
                {item?.rate}
              </Grid>
            )}

            {submission_type === 'LOST' && (
              <Grid
                item={true}
                xs={12}
                md={billType === 'MIX' ? 2 : 3.5}
                sx={{ display: 'flex', alignItems: 'center', pl: 3 }}
              >
                {item?.lostItemCost}
              </Grid>
            )}
            {billType === 'MIX' && entryType === EntryType.IN && (
              <Grid
                item={true}
                xs={12}
                md={submission_type === 'LOST' ? 1.5 : 3.5}
                sx={{ display: 'flex', alignItems: 'center', pl: 3 }}
              >
                <span
                  style={{
                    paddingLeft: 7,
                    paddingRight: 7,
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderRadius: 20,
                    backgroundColor: item.rate === 0 ? '#eb627a' : '#314d67',
                    color: 'white',
                  }}
                >
                  {item.rate === 0 ? 'per sqft' : 'per item'}
                </span>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={1.5} md textAlign="right" pr={2}>
          <IconButton
            size="small"
            sx={{
              mt: {
                xs: 2,
                md: 1,
                color: 'crimson',
              },
            }}
            onClick={() => {
              deleteItem(item?.item?.id, item?.outAreaEntryId)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default PreviewItem
