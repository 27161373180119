import { Box, Typography, Grid, useTheme } from '@mui/material'
import logo from './images/app_logo_blu.png'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import ApplicationLinks from '../../ApplicationLinks'

const FooterLink = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.common.white};
      `
)

export const WebsiteFooter = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#314367',
        height: '9vh',
        color: `${theme.palette.common.white}`,
      }}
    >
      <Grid container sx={{ width: '70%' }}>
        <Grid item xs={6}>
          <Box>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <img src={logo} alt="shuttering" style={{ width: 44 }} />
              </Grid>
              <Grid item>
                {' '}
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography> © TEJSE {currentYear}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {' '}
                      Tejse Online Services Private Limited
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Box>
            <FooterLink href={ApplicationLinks.privacyPolicyLink()}>
              Privacy Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.termsAndConditionsink()}>
              Terms and Conditions
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.shippingPolicyLink()}>
              Shipping Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.refundAndCancelationLink()}>
              Cancellation and Refund Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.contactLink()}>
              Contact Us
            </FooterLink>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
