import { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { ChallanDropDown } from '../../content/applications/slips/ChallanDropDown'

// Utils
import {
  getEncodedFormattedDate,
  getFormattedDateTimeIST,
  replace12AmWithCurrentTime,
} from '../../utils'
import { getNextChallanNo } from './services'
import { useAppDispatch } from '../../../src/hooks'
import { showAlertAction } from '../../../src/store/alerts'
import { HORZ_SPACING } from '../../../src/content/applications/IssueItems/Item'

export const ChallanSection = ({
  challanNo,
  bill_id,
  userDefinedChallanNumber,
  setDefinedChallanNumber,
  selectedChallan,
  setSelectedChallan,
  setReadyToSubmit,
  date,
  setDate,
  datePickerOpen,
  setDatePickerOpen,
  singleRow = true,
  challan_type,
  mdValue,
}) => {
  const datePickerRef = useRef(null)
  const dispatchAction = useAppDispatch()

  const fetchChallanNoByDate = (date: string) => {
    getNextChallanNo(bill_id, challan_type ? challan_type : 'OUT', date).then(
      (response) => {
        if (response.success) {
          setDefinedChallanNumber(response.data)
        } else {
          dispatchAction(
            showAlertAction({
              message: response.message,
              severity: 'error',
            })
          )
        }
      }
    )
  }

  const parts = useMemo(() => {
    if (Number(mdValue) > 0) {
      return Number(mdValue)
    }
    if (selectedChallan?.id === 0) {
      return singleRow ? 4 : 12
    }
    return singleRow ? 6 : 12
  }, [selectedChallan?.id, singleRow])

  useEffect(() => {
    if (selectedChallan?.id === 0) {
      setDefinedChallanNumber(selectedChallan.slipNo)
    } else {
      setDefinedChallanNumber(null)
      setDate(null)
    }
  }, [selectedChallan?.id])

  return (
    <Grid container spacing={0}>
      <Grid item md={parts}>
        <ChallanDropDown
          challanNo={challanNo}
          accountId={Number(bill_id)}
          onChallanChange={(challan) => {
            setSelectedChallan(challan)
          }}
          challan_type={challan_type}
        />
      </Grid>
      {selectedChallan?.id === 0 && (
        <>
          <Grid item md={parts} pl={HORZ_SPACING}>
            <TextField
              size="small"
              name="challanId"
              onChange={(e) => {
                setDefinedChallanNumber(e.target.value)
              }}
              value={userDefinedChallanNumber}
              label="challan No"
              fullWidth
            />
          </Grid>
          <Grid item md={parts} pl={HORZ_SPACING}>
            <DateTimePicker
              label="Select Date and time"
              value={date}
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              inputRef={datePickerRef}
              onChange={(newValue) => {
                setReadyToSubmit(true)
                setDate(replace12AmWithCurrentTime(newValue))
                fetchChallanNoByDate(getEncodedFormattedDate(newValue))
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
              format="dd/MM/yyyy hh:mm a"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
